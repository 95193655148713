import { useEffect, useState } from 'react';
import { useStore } from '../stores-v2/StoreContext';
import type { DatoContext } from '../stores-v2/types';
import type {
  UserResource,
  SessionResource,
  OrganizationResource,
} from '@clerk/types';

interface InitParams {
  storyId: string | null | undefined;
  env?: string;
  datoCtx?: DatoContext;
  // Clerk params
  clerkUser?: UserResource | null;
  clerkSession?: SessionResource | null;
  isClerkLoaded?: boolean;
}

export function useStoreInitialization({
  storyId,
  env,
  datoCtx,
  clerkUser,
  clerkSession,
  isClerkLoaded,
}: InitParams) {
  const rootStore = useStore();
  const [isReady, setIsReady] = useState(false);
  console.debug('Loading:', {
    storyId,
    env,
    datoCtx,
    clerkUser,
    clerkSession,
  });

  useEffect(() => {
    console.debug('Initializing store with:', {
      storyId,
      env,
      datoCtx,
      clerkUser,
      clerkSession,
    });

    const handleUnauthorizedRedirect = () => {
      const redirectUrl = process.env.REACT_APP_API_URL;
      if (redirectUrl) {
        const currentUrl = window.location.href;
        const encodedReturnUrl = encodeURIComponent(currentUrl);
        window.location.href = `${redirectUrl}?returnUrl=${encodedReturnUrl}`;
      } else {
        console.error('No redirect URL configured for unauthorized access');
      }
    };

    async function initializeStore() {
      const hasClerkAuth = !!clerkSession;
      const hasDatoAuth = !!datoCtx?.currentUserAccessToken;
      console.debug('Auth status:', { hasClerkAuth, hasDatoAuth });

      // Check if we have any valid auth
      if (!hasClerkAuth && !hasDatoAuth) {
        console.log('No authentication found, redirecting...');
        handleUnauthorizedRedirect();
        return;
      }

      // Initialize store with basic info
      const organization =
        clerkUser?.organizationMemberships?.[0]?.organization;
      rootStore.initialize({
        storyId,
        env: env || '',
        datoContext: datoCtx,
        organization: organization
          ? ({
              id: organization.id,
              name: organization.name,
              slug: organization.slug,
            } as OrganizationResource)
          : undefined,
      });

      // If we have clerk auth, set up clerk context
      if (hasClerkAuth && clerkSession) {
        try {
          const token = await clerkSession.getToken();
          rootStore.userIdentityStore.setClerkContext(
            //@ts-ignore
            clerkUser,
            clerkSession,
            token || null,
          );
        } catch (error) {
          console.error('Failed to get clerk token:', error);
          handleUnauthorizedRedirect();
          return;
        }
      }

      setIsReady(true);
    }

    initializeStore();
  }, [storyId, env, datoCtx, clerkUser, clerkSession, isClerkLoaded]);

  return {
    isReady,
    userIdentity: rootStore.userIdentityStore.getUserIdentity(),
  };
}
