import { RequestDocument } from 'graphql-request';
import {
  MEDIA_QUERY_FRAGMENT,
  SONG_QUERY_FRAGMENT,
  VIDEO_FILE_GQL_FRAGMENT,
} from './media-gql';
import {
  BASIC_TEXT_BRANDING_QUERY,
  KARAOKE_TEXT_BRANDING_QUERY,
  SHOWCASE_BRANDING_QUERY,
} from './branding-gql';
import {
  REFERENCING_SHARED_CONTENT,
  REFERENCING_CAPTION,
} from './shared-content-gql';
import { TRANSCRIPTION_QUERY_FRAGMENT } from './misc-gql';

export const STORY_QUERY: RequestDocument = `query getStory($id: ItemId) {
  story(filter: {id: {eq: $id}}) {
    id
    slug
    hash
    title(locale: en)
    description(locale: en)
    brollPage
    contributors {
      id
      name
      email
    }
    primaryShowcase {
      id
      title
      organization
      slug
      logo {
        url
      }
      mainImage {
        url
      }
      organizationArtifacts ${MEDIA_QUERY_FRAGMENT}
      organizationArtifactsVideo ${MEDIA_QUERY_FRAGMENT}
      organizationLogos ${MEDIA_QUERY_FRAGMENT}
    }
    _allReferencingShowcases {
      id
      title
      organization
      slug
      logo {
        url
      }
      mainImage {
        url
      }
      profiles {
        id
        name
        platforms
      }
      organizationLogos ${MEDIA_QUERY_FRAGMENT}
      organizationArtifacts ${MEDIA_QUERY_FRAGMENT}
      organizationArtifactsVideo ${MEDIA_QUERY_FRAGMENT}

      lowerThirdTextBranding ${BASIC_TEXT_BRANDING_QUERY}
      karaokeTextBranding ${KARAOKE_TEXT_BRANDING_QUERY}
      lastUsedTemplates
      branding ${SHOWCASE_BRANDING_QUERY}
    }
    thumbnail {
      url
    }
    aiGeneratedContent{
      id
      prompt
      generatedContent
    }
    _allReferencingSharedContents
    ${REFERENCING_SHARED_CONTENT}

    _allReferencingCaptions ${REFERENCING_CAPTION}

    alreadyGeneratedContent
    aiResponse
    byExternalUser
    externalUploadStatus
    savedBlog
    savedTalkingPointContent
    savedEmail
    shareableImages {
      id
      imagefile {
        id
        title
        url
        width
        height
        responsiveImage {
          srcSet
          alt
          title
        }
        _createdAt
      }
      quote
      storytellerName
      _allReferencingSharedContents
      ${REFERENCING_SHARED_CONTENT}

      _allReferencingCaptions ${REFERENCING_CAPTION}
    }
    transcription ${TRANSCRIPTION_QUERY_FRAGMENT}
    originalVideo ${VIDEO_FILE_GQL_FRAGMENT}
    finalVideo {
      id
      title
      thumbnail {
        url
      }
      videoFilePrimary
        ${VIDEO_FILE_GQL_FRAGMENT}
      videoStatus
      sourcePlatform
    }
    otherVideos {
      id
      title
      videoStatus
      sourcePlatform
      isClientReady
      isDownloadEnabled
      isHidden
      clipJson
      lastActionJson
      thumbnail {
        url
      }
      shareableImageId
      videoFilePrimary
        ${VIDEO_FILE_GQL_FRAGMENT}
      slug
      hash
      aspectRatio
      videoJson
      aiGeneratedContent {
        id
        prompt
        generatedContent
      }
      associatedVideos {
        id
        aspectRatio
        videoStatus
        sourcePlatform
        isClientReady
        isHidden
        clipJson
        lastActionJson
        videoJson
        thumbnail {
          url
        }
        shareableImageId
        videoFilePrimary
          ${VIDEO_FILE_GQL_FRAGMENT}
      }
      _allReferencingSharedContents
      ${REFERENCING_SHARED_CONTENT}
      _allReferencingCaptions ${REFERENCING_CAPTION}
    }
    tags {
      name
    }
    storyTeller {
      name
    }
    storyArtifacts ${MEDIA_QUERY_FRAGMENT}
    storyAssets ${MEDIA_QUERY_FRAGMENT}
    aiPhotos ${MEDIA_QUERY_FRAGMENT}
    storyArtifactsVideo ${MEDIA_QUERY_FRAGMENT}
    myAudios ${SONG_QUERY_FRAGMENT}
    useAws
  }
}`;

export const NEW_STORIES_COMPLETION_QUERY: RequestDocument = `query getStories($ids: [ItemId]) {
  allStories(filter: {id: {in: $ids}}) {
      id,
      externalUploadStatus
      aiResponse
      uploadLog {
        id
        initialUpload
        submitToAuphonic
        checkAuphonicProductionStatus
        transferFilesToProductionBucket
        ffmpegProcessing
        compressVideo
        generateWaveform
        awsTranscribe
        checkAwsTranscribeStatus
        revaiTranscribe
        checkRevaiTranscribeStatus
        combineResults
        extractThumbnailsFromLowRes
        handleFailure
      }
      useAws
      datoHostedVideo: originalVideo ${VIDEO_FILE_GQL_FRAGMENT}
      transcription ${TRANSCRIPTION_QUERY_FRAGMENT}
  }
}`;

export const STORY_REFERENCING_ALBUMS_ACCESS_QUERY: RequestDocument = `query getStory($id: ItemId) {
  story(filter: {id: {eq: $id}}) {
    albums: _allReferencingShowcases {
      id
      title
      organization
      slug
      adminList {
        ... on PersonRecord {
          email
        }
      }
      allowList {
        ... on PersonRecord {
          email
        }
      }
    }
  }
}`;
