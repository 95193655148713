import { buildBlockRecord } from '@datocms/cma-client-browser';
import VideoCreatorStore from '@src/stores/VideoCreatorStore';
import {
  AIFlowEvaluatedFunction,
  AIFlowQueryResult,
  AiFlowRetryCondition,
  SUPPORTED_AI_CATEGORIES,
} from '../types.ts/ai_prompts';
import { AIFlow } from './AIFlow';
import { AIFlowStep } from './AIFlowStep';
import {
  AI_FLOW_BY_TITLE_AND_CATEGORY_ACTIVE_QUERY,
  AI_FLOWS_BY_CATEGORY_AND_ACTIVE_QUERY,
} from '../gql/ai-promts-gql';

export const getAllAiFlowsByCategory = async (
  videoCreator: VideoCreatorStore,
  categories: SUPPORTED_AI_CATEGORIES[],
) => {
  try {
    const response = (await videoCreator.gqlClient?.request(
      AI_FLOWS_BY_CATEGORY_AND_ACTIVE_QUERY,
      {
        categories,
      },
    )) as {
      allAiFlows: Pick<AIFlowQueryResult, 'id' | 'title' | 'category'>[];
    };
    console.log('response', response);
    return response.allAiFlows;
  } catch (err) {
    //debugger;
    console.error('Error loading AI flows', err);
    return [];
  }
};

export const loadAIFlow = async (
  videoCreator: VideoCreatorStore,
  preset: {
    title: string;
    category: string;
  },
) => {
  //debugger;
  let result;
  try {
    result = (await videoCreator.gqlClient?.request(
      AI_FLOW_BY_TITLE_AND_CATEGORY_ACTIVE_QUERY,
      {
        title: preset.title,
        category: preset.category,
      },
    )) as { aiFlow: AIFlowQueryResult };
  } catch (err) {
    //debugger;
    console.error('Error loading AI flow', err);
    return null;
  }
  const aiFlowResult = result?.aiFlow;
  //debugger;
  const steps = aiFlowResult.steps.map((step) => {
    return new AIFlowStep({
      id: step.id,
      label: step.label,
      systemMessageTemplate: step.systemMessageTemplate,
      userPromptTemplate: step.userPromptTemplate,
      includeMessagesFromStepId: step.overrideMessagesFromStep,
      maxAttempts: step.maxAttempts,
      iterateOver: step.iterateOver,
      models: step.modelName.split(',').map((modelName) => modelName.trim()),
      config: {
        temperature: step.temperature, // temperatureFunction: step.temperatureFunction,
      },
      stream: step.stream,
      filter: undefined, // filterFunction: step.filterFunction,
      mapper: undefined, // mapperFunction: step.mapperFunction,
      reducer: undefined, // reducerFunction: step.reducerFunction,
      retryCondition: step.retryConditionFunction
        ? (evalFunction(step.retryConditionFunction) as AiFlowRetryCondition)
        : true, // retryConditionFunction: step.retryConditionFunction,
    });
  });
  //debugger;
  return new AIFlow({
    videoCreator: videoCreator,
    title: aiFlowResult.title,
    steps,
    context: {},
    logEnabled: aiFlowResult.logEnabled,
  }); //aiFlowResult.resultMapperFunction)
};

const evalFunction = (functionString: string) => {
  try {
    const Fn = eval(
      functionString.replaceAll('\n', ''),
    ) as AIFlowEvaluatedFunction;
    //debugger;
    return Fn;
  } catch (err) {
    //debugger;
    console.error('Error evaluating function', err);
    return null;
  }
};

export const saveAiFlowLogs = async (
  videoCreator: VideoCreatorStore,
  logs: { flow: string; time: Date; stepLabel: string; message: string }[],
) => {
  //debugger;
  if (logs.length === 0) return;
  const flowTitle = logs[0].flow;

  const stepLogBlocks = [];
  try {
    const stepLogBlockTypeId = (
      await videoCreator.datoClient!.itemTypes.find('ai_flow_step_log')
    ).id as string;
    const stepLogMessageBlockTypeId = (
      await videoCreator.datoClient!.itemTypes.find('ai_flow_log_message')
    ).id as string;

    let currentStepLabel = '';
    let messageLogs = [];
    //debugger;
    for (let log of logs) {
      if (log.stepLabel !== currentStepLabel && currentStepLabel) {
        stepLogBlocks.push(
          buildBlockRecord({
            item_type: { type: 'item_type', id: stepLogBlockTypeId },
            step_label: log.stepLabel,
            message_log: messageLogs,
          }),
        );

        messageLogs = [];
      }
      currentStepLabel = log.stepLabel;

      messageLogs.push(
        buildBlockRecord({
          item_type: { type: 'item_type', id: stepLogMessageBlockTypeId },
          timestamp: log.time,
          message: log.message,
        }),
      );
    }
    stepLogBlocks.push(
      buildBlockRecord({
        item_type: { type: 'item_type', id: stepLogBlockTypeId },
        step_label: logs[logs.length - 1].stepLabel,
        message_log: messageLogs,
      }),
    );

    //debugger;
  } catch (err) {
    //debugger;
    console.log(err);
  }
  try {
    const itemType =
      await videoCreator.datoClient!.itemTypes.find('ai_flow_log');

    const savedLog = await videoCreator.datoClient!.items.create({
      item_type: { type: 'item_type', id: itemType.id },
      flow_title: flowTitle,
      logs: stepLogBlocks,
    });
    return savedLog.id;
  } catch (err) {
    //debugger;
    console.error('Error saving AI flow logs', err);
    return null;
  }
};
