import { makeAutoObservable } from 'mobx';
import { UserIdentityStore } from './UserIdentityStore';
import { AnalyticsStore } from './AnalyticsStore';
import type { OrganizationResource } from '@clerk/types';
import type { DatoContext } from './types';

interface InitializeParams {
  storyId: string | null | undefined;
  env: string;
  datoContext?: DatoContext;
  organization: OrganizationResource | undefined;
}

export class RootStore {
  userIdentityStore: UserIdentityStore;
  analyticsStore: AnalyticsStore;

  initialized: boolean = false;
  storyId: string | undefined | null = null;
  organization: OrganizationResource | undefined = undefined;

  constructor() {
    this.userIdentityStore = new UserIdentityStore(this);
    this.analyticsStore = new AnalyticsStore(this);

    makeAutoObservable(this, {
      userIdentityStore: false,
      analyticsStore: false,
    });
  }

  initialize({ storyId, env, datoContext }: InitializeParams) {
    this.storyId = storyId;
    this.userIdentityStore.setEnvironment(env);

    if (datoContext) {
      this.userIdentityStore.setDatoContext(datoContext);
    }
    this.analyticsStore.identifyUser();

    this.initialized = true;
  }

  // We're ready if we're initialized and have either:
  // 1. Dato context
  // 2. Valid environment for external users
  get isReady(): boolean {
    return (
      this.initialized &&
      (this.userIdentityStore.hasDatoContext ||
        !!this.userIdentityStore.environment)
    );
  }

  // Helper method to get current auth type
  get authType(): 'dato' | 'clerk' | null {
    if (this.userIdentityStore.hasDatoContext) return 'dato';
    if (this.userIdentityStore.currentIdentity?.source === 'clerk')
      return 'clerk';
    return null;
  }

  // Helper to check if we have valid authentication
  get hasValidAuth(): boolean {
    return this.userIdentityStore.currentIdentity !== null;
  }
}
