import { createContext, useContext, ReactNode } from 'react';
import { configure } from 'mobx';
import { RootStore } from './RootStore';
import { UserIdentityStore } from './UserIdentityStore';
import { AnalyticsStore } from './AnalyticsStore';

if (process.env.NODE_ENV === 'development') {
  configure({
    enforceActions: 'observed',
  });
}

const StoreContext = createContext<RootStore | null>(null);

interface StoreProviderProps {
  children: ReactNode;
  initialState?: Partial<RootStore> & {
    userIdentityStore?: Partial<UserIdentityStore>;
    analyticsStore?: Partial<AnalyticsStore>;
  };
}

export const StoreProvider = ({
  children,
  initialState,
}: StoreProviderProps) => {
  const store = new RootStore();

  // Initialize store with initial state, and handle nested stores
  if (initialState) {
    // Handle top-level properties
    const { userIdentityStore, analyticsStore, ...topLevelState } =
      initialState;
    Object.assign(store, topLevelState);

    // Handle nested stores
    if (userIdentityStore) {
      Object.assign(store.userIdentityStore, userIdentityStore);
    }
    if (analyticsStore) {
      Object.assign(store.analyticsStore, analyticsStore);
    }
  }

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

// Base store hook
export const useStore = () => {
  const context = useContext(StoreContext);
  if (!context) {
    throw new Error('useStore must be used within StoreProvider');
  }
  return context;
};

// Convenience hooks
export const useUserIdentity = () => {
  const store = useStore();
  return store.userIdentityStore;
};

export const useAnalytics = () => {
  const store = useStore();
  return store.analyticsStore;
};
