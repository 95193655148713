import React from 'react';
import { ClerkProvider } from '@clerk/clerk-react';

interface WithClerkConfig {
  signInUrl: string;
  publishableKey: string;
}

export const withClerkProvider = (
  Component: React.ComponentType,
  config?: Partial<WithClerkConfig>,
) => {
  return (props: any) => {
    const providerConfig = {
      signIn: { url: config?.signInUrl || process.env.REACT_APP_API_URL! },
      publishableKey:
        config?.publishableKey || process.env.REACT_APP_CLERK_PUBLISHABLE_KEY!,
    };

    return (
      <ClerkProvider {...providerConfig}>
        <Component {...props} />
      </ClerkProvider>
    );
  };
};
