import VideoCreatorStore from '@src/stores/VideoCreatorStore';
import {
  PunctuationMark,
  TranscriptChange,
  TranscriptElement,
  TranscriptTextElement,
} from '../types.ts/transcript';

import { inDebugMode } from '../utility/debug';
import getChangeHandler from './transcriptionChangeHandlers/getChangeHandler';

const EPSILON = 1e-6;
export default class TranscriptionProcessor {
  private videoCreator: VideoCreatorStore;
  private transcriptionElements?: TranscriptElement[];
  private transcriptionChanges: TranscriptChange[] = [];

  constructor(videoCreator: VideoCreatorStore) {
    this.videoCreator = videoCreator;
  }

  finalTranscriptionElements?: TranscriptElement[];
  onFinalTranscriptionElementsChange: (elements: TranscriptElement[]) => void =
    () => {};

  setTranscriptionElements(transcriptionElements: TranscriptElement[]) {
    this.transcriptionElements = transcriptionElements;
  }

  setTranscriptionSnapshot(elements: TranscriptElement[]) {
    this.transcriptionChanges = [];
    this.finalTranscriptionElements = elements.map((el) => ({ ...el }));
    this.onFinalTranscriptionElementsChange(this.finalTranscriptionElements);
  }

  setTranscriptionChanges(changes: TranscriptChange[]) {
    this.transcriptionChanges = changes;
  }

  getTranscriptionChanges() {
    return structuredClone(this.transcriptionChanges);
  }

  getFinalTranscriptionText() {
    return (
      this.finalTranscriptionElements
        ?.filter((el) => el.state !== 'removed' && el.state !== 'cut')
        .map((el) => el.value || '')
        .join('') || ''
    );
  }

  getFinalTranscriptionElements() {
    return this.finalTranscriptionElements || [];
  }

  getTranscriptionElements() {
    return this.transcriptionElements || [];
  }

  applyChangesToOriginalTranscription(transcriptChanges: TranscriptChange[]) {
    if (!this.transcriptionElements)
      throw new Error('No transcription elements');
    this.finalTranscriptionElements = this.transcriptionElements.map(
      (el, index) => ({ ...el, initial_index: index }),
    );
    for (let i = 0; i < transcriptChanges.length; i++) {
      this.applyChange(
        transcriptChanges[i],
        i !== transcriptChanges.length - 1,
      );
    }

    this.transcriptionChanges = transcriptChanges;
    this.onFinalTranscriptionElementsChange(this.finalTranscriptionElements!);
  }

  applyChangesToCurrentTranscription(transcriptChanges: TranscriptChange[]) {
    if (!this.finalTranscriptionElements)
      throw new Error('No transcription elements');

    for (let i = 0; i < transcriptChanges.length; i++) {
      this.applyChange(
        transcriptChanges[i],
        i !== transcriptChanges.length - 1,
      );
    }

    this.onFinalTranscriptionElementsChange(this.finalTranscriptionElements!);
  }

  applyChange(change: TranscriptChange, isBatched: boolean = false) {
    if (inDebugMode()) console.log('Apply Change: ', change);

    if (!change) {
      console.error('Invalid change (null)');
      return;
    }

    const handler = getChangeHandler(change);
    handler.apply(
      this.finalTranscriptionElements!,
      change,
      this.transcriptionElements!,
    );

    if (!isBatched) {
      this.onFinalTranscriptionElementsChange(this.finalTranscriptionElements!);
    }
    this.transcriptionChanges.push(change);
  }

  replaceTextElement(startIndex: number, endIndex: number, newValue: string) {
    this.applyChange(this.getReplaceChange(startIndex, endIndex, newValue));
  }

  replaceElementTs(
    startIndex: number,
    endIndex: number,
    newValueTs: number,
    newValueEndTs: number,
  ) {
    this.applyChange(
      this.getReplaceTsChange(startIndex, endIndex, newValueTs, newValueEndTs),
    );
  }

  hideKaraoke(
    boundaries: { startIndex: number; endIndex: number },
    isBatched: boolean = false,
  ) {
    this.applyChange(
      this.getMuteChange(boundaries.startIndex, boundaries.endIndex),
      isBatched,
    );
  }

  restoreMutedTextElement(
    fromElement: number,
    toElement: number,
    isBatched: boolean = false,
  ) {
    const transcriptElements = this.finalTranscriptionElements!;
    if (
      fromElement < 0 ||
      toElement <= fromElement ||
      toElement > transcriptElements.length
    ) {
      throw Error('Invalid elements range');
    }

    this.applyChange(
      this.getRestoreMuteChange(fromElement, toElement),
      isBatched,
    );
  }

  addPunctuation(
    code: 'Comma' | 'Period' | 'Space' | 'Enter',
    position: number,
    options: { karaoke_break?: boolean } = {},
  ) {
    this.applyChange(this.getAddPunctuationChange(code, position, options));
  }

  addKaraokeBreaks(positions: number[]) {
    this.applyChange(this.getAddKaraokeBreakChange(positions));
  }

  removeKaraokeBreak(position: number) {
    this.applyChange(this.getRemoveKaraokeBreakChange(position));
  }

  removeAllKaraokeBreaks() {
    this.applyChange(this.getRemoveAllKaraokeBreaksChange());
  }

  removeAllKaraokeMutes() {
    // TODO: Currentl
    this.applyChange(
      // We subtract 1 because MuteChange handler adds 1...
      // Keeping the same logic to avoid downstream changes.
      this.getRestoreMuteChange(0, this.finalTranscriptionElements!.length - 1),
    );
  }

  addPhotoHighlight(
    fromElement: number,
    toElement: number,
    photoHighlightId: string,
  ) {
    const index = fromElement;
    const count = toElement - fromElement + 1;
    const elements = this.finalTranscriptionElements!;
    for (let i = 0; i < count; i++) {
      elements[index + i].photo_highlight_id = photoHighlightId;
    }
    elements[index + count - 1].last_photo_highlight = photoHighlightId
      ? true
      : undefined;
    this.onFinalTranscriptionElementsChange(elements);
  }

  removeAllPhotoHighlights() {
    const elements = this.finalTranscriptionElements!;
    for (const element of elements) {
      if (element.photo_highlight_id) {
        delete element.photo_highlight_id;
        delete element.last_photo_highlight;
      }
    }
    this.onFinalTranscriptionElementsChange(this.finalTranscriptionElements!);
  }

  removeSinglePhotoHighlight(id: string) {
    const elements = this.finalTranscriptionElements!;
    for (const element of elements) {
      if (element.photo_highlight_id && element.photo_highlight_id === id) {
        delete element.photo_highlight_id;
        delete element.last_photo_highlight;
      }
    }
    this.onFinalTranscriptionElementsChange(this.finalTranscriptionElements!);
  }

  removeAllAutoPhotoHighlights() {
    const elements = this.finalTranscriptionElements!;
    let lastFoundHighlightId;
    for (const element of elements) {
      if (
        element.photo_highlight_id &&
        (lastFoundHighlightId === element.photo_highlight_id ||
          this.videoCreator.punchListManager.punchListItems?.find(
            (p) =>
              p.id === element.photo_highlight_id && p.sub_type !== 'manual',
          ))
      ) {
        lastFoundHighlightId = element.photo_highlight_id;
        delete element.photo_highlight_id;
        delete element.last_photo_highlight;
      }
    }
    this.onFinalTranscriptionElementsChange(this.finalTranscriptionElements!);
  }

  private getReplaceChange(
    startIndex: number,
    endIndex: number,
    newValue: string,
  ): TranscriptChange {
    return {
      type: 'replace',
      index: startIndex,
      endIndex,
      count: Math.max(endIndex - startIndex + 1, 1),
      oldValue: this.finalTranscriptionElements!.slice(startIndex, endIndex + 1)
        .map((el) => el.value || '')
        .join(''),
      newValue: newValue,
      datetime: new Date().toISOString(),
      command: `replaceTextElement(${startIndex}, ${endIndex}, ${newValue})`,
    };
  }

  private getReplaceTsChange(
    startIndex: number,
    endIndex: number,
    newTs: number,
    newEndTs: number,
  ): TranscriptChange {
    return {
      type: 'replace_ts',
      index: startIndex,
      count: endIndex - startIndex + 1,
      oldTs: this.finalTranscriptionElements![startIndex].ts || 0,
      newTs: newTs || 0,
      oldEndTs: this.finalTranscriptionElements![endIndex].end_ts || 0,
      newEndTs: newEndTs || 0,
      datetime: new Date().toISOString(),
      command: `replaceElementTs(${startIndex}, ${endIndex}, ${newTs}, ${newEndTs})`,
    };
  }

  private getMuteChange(
    startIndex: number,
    endIndex: number,
  ): TranscriptChange {
    return {
      type: 'mute',
      index: startIndex,
      count: endIndex - startIndex + 1,
      datetime: new Date().toISOString(),
      command: `hideKaraoke({startIndex: ${startIndex}, endIndex: ${endIndex}})`,
    };
  }

  private getRestoreMuteChange(
    startIndex: number,
    endIndex: number,
  ): TranscriptChange {
    return {
      type: 'restore_mute',
      index: startIndex,
      count: endIndex - startIndex,
      datetime: new Date().toISOString(),
      command: `restoreMutedTextElement({fromElement: ${startIndex}, toElement: ${endIndex}})`,
    };
  }

  private getAddPunctuationChange(
    code: 'Comma' | 'Period' | 'Space' | 'Enter',
    position: number,
    options: { karaoke_break?: boolean },
  ): TranscriptChange {
    const punctMap = {
      Comma: ',',
      Period: '.',
      Space: ' ',
      Enter: '\n',
    } as Record<typeof code, PunctuationMark>;

    return {
      type: 'insert_punct',
      index: position,
      count: 1,
      value: punctMap[code],
      datetime: new Date().toISOString(),
      command: `addPunctuation(${code}, ${position}, ${JSON.stringify(
        options,
      )})`,
      options,
    };
  }

  private getAddKaraokeBreakChange(positions: number[]): TranscriptChange {
    return {
      type: 'add_karaoke_break',
      index: positions,
      count: 0,
      datetime: new Date().toISOString(),
      command: `addKaraokeBreak(${
        positions.length === 1 ? positions[0] : 'all'
      })`,
    };
  }

  private getRemoveKaraokeBreakChange(position: number): TranscriptChange {
    return {
      type: 'remove_karaoke_break',
      index: position,
      count: 0,
      datetime: new Date().toISOString(),
      command: `removeKaraokeBreak(${position})`,
    };
  }

  private getRemoveAllKaraokeBreaksChange(): TranscriptChange {
    return {
      type: 'remove_all_karaoke_breaks',
      datetime: new Date().toISOString(),
      command: `removeAllKaraokeBreaks()`,
      count: -1,
      index: null,
    };
  }

  findClosestIndexToTimestamp(
    ts: number,
    time: 'ts' | 'end_ts' = 'ts',
    helperStrategy?: 'ts_lookbehind',
  ) {
    let closest: number = 0;
    let minDiff = Infinity;

    this.finalTranscriptionElements?.forEach((element, index) => {
      let currTs = element[time];
      if (currTs && element.state !== 'removed' && element.state !== 'cut') {
        const diff = Math.abs(currTs - ts);
        if (diff < minDiff) {
          minDiff = diff;
          closest = index;
        }
      }
      if (helperStrategy === 'ts_lookbehind') {
        currTs = element.ts;
        if (currTs && element.state !== 'removed' && element.state !== 'cut') {
          const diff = Math.abs(currTs - ts);
          if (diff < minDiff) {
            minDiff = diff;
            closest = index - 1;
          }
        }
      }
    });
    return closest;
  }

  reapplyEdits(oldTranscriptElements: TranscriptElement[]) {
    const newTranscriptElements = this.finalTranscriptionElements!;
    let lastNewElement = 0;
    let prevInitialElement;
    let breaks = [];
    let changes: TranscriptChange[] = [];
    this.fixOverlappingKaraokePlacements(oldTranscriptElements);

    for (let i = 0; i < oldTranscriptElements.length; i++) {
      let oldElement = oldTranscriptElements[i];
      if (oldElement.initial_index == null || oldElement.initial_index < 0) {
        if (oldElement.karaoke_break && prevInitialElement) {
          oldElement = prevInitialElement;
          oldElement.karaoke_break = true;
        } else {
          continue;
        }
      } else if (oldElement.value?.trim() !== '') {
        prevInitialElement = oldElement;
      }

      if (
        !oldElement.karaoke_break &&
        !oldElement.karaoke_break_end_ts_diff &&
        !oldElement.karaoke_break_start_ts_diff &&
        oldElement.state !== 'replaced' &&
        oldElement.state !== 'muted'
      )
        continue;

      for (let j = lastNewElement; j < newTranscriptElements.length; j++) {
        const newElement = newTranscriptElements[j];
        if (newElement.initial_index === oldElement.initial_index) {
          if (
            oldElement.state === 'replaced' &&
            !['cut', 'removed'].includes(newElement.state || '')
          ) {
            changes.push(this.getReplaceChange(j, j, oldElement.value || ''));
          } else if (
            oldElement.state === 'muted' &&
            !['cut', 'removed'].includes(newElement.state || '')
          ) {
            changes.push(this.getMuteChange(j, j));
          }

          if (
            oldElement.karaoke_break &&
            !['cut', 'removed'].includes(newElement.state || '')
          ) {
            breaks.push(j);
          }
          if (oldElement.karaoke_break_start_ts_diff) {
            const karaoke_break_start_ts_diff =
              (oldElement.ts || 0) -
              (newElement.ts || 0) +
              oldElement.karaoke_break_start_ts_diff;
            changes.push({
              type: 'change_karaoke_start_break_time',
              index: j,
              timeShift: karaoke_break_start_ts_diff,
              count: 1,
              datetime: new Date().toISOString(),
              version: 2,
            });
          }
          if (oldElement.karaoke_break_end_ts_diff) {
            const karaoke_break_end_ts_diff =
              (oldElement.end_ts || 0) -
              (newElement.end_ts || 0) +
              oldElement.karaoke_break_end_ts_diff;
            changes.push({
              type: 'change_karaoke_end_break_time',
              index: j,
              timeShift: karaoke_break_end_ts_diff,
              count: 1,
              datetime: new Date().toISOString(),
              version: 2,
            });
          }
          lastNewElement = j;
          break;
        }
      }
    }
    changes.push({
      type: 'add_karaoke_break',
      index: breaks,
      count: 0,
      datetime: new Date().toISOString(),
      command: `addKaraokeBreak(all)`,
    });

    this.applyChangesToCurrentTranscription(changes);
    this.onFinalTranscriptionElementsChange(this.finalTranscriptionElements!);
  }

  private fixOverlappingKaraokePlacements(
    transcriptionElements: TranscriptElement[],
  ) {
    let prevTextElement: TranscriptTextElement | null = null;
    for (let element of transcriptionElements) {
      if (
        ['cut', 'removed'].includes(element.state || '') ||
        element.type !== 'text' ||
        (!element.karaoke_break_end_ts_diff &&
          !element.karaoke_break_start_ts_diff)
      )
        continue;

      if (
        element.ts < 0 ||
        element.ts + (element.karaoke_break_start_ts_diff || 0) < 0
      ) {
        element.karaoke_break_start_ts_diff = -element.ts;
      }

      if (
        prevTextElement?.karaoke_break_end_ts_diff ||
        element.karaoke_break_start_ts_diff
      ) {
        if (
          (prevTextElement?.end_ts || 0) +
            (prevTextElement?.karaoke_break_end_ts_diff || 0) >
          element.ts + (element.karaoke_break_start_ts_diff || 0) + EPSILON
        ) {
          delete prevTextElement?.karaoke_break_end_ts_diff;
          delete element.karaoke_break_start_ts_diff;
        }
      }
      prevTextElement = element;
    }
  }
}
