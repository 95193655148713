import { MouseEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ReactDOMServer from 'react-dom/server';
import { observer } from 'mobx-react-lite';
import { marked } from 'marked';

import { AiGeneratedContent } from '../../types.ts/story';
import ContentShare from '../common/ContentShare';
import RegenerateButton from '../common/RegenerateButton';
import SpinningLoading from '../SpinningLoading';

import PhotoModal from '../common/PhotosModal';
import PhotoModalTop from '../common/PhotoModalTop';
import Modal from '../Modal';
import { ImageKey, ImageWithType } from '../../types.ts/general';
import EllipsisIcon from '../../svgs/EllipsisIcon';
import { useOutsideAlerter } from '../transcript/useClickOutside';
import { ActionButton, ActionsWrapper } from '../../styles/mainStyle';
import CameraIcon from '../../svgs/CameraIcon';
import {
  handleCopyToClipboard,
  initializeContentStudioContent,
} from '../../utility/general';
import { runInAction } from 'mobx';
import { useUser } from '@clerk/clerk-react';
import { useFlagsCombination } from '../../utility/useFlagsCombination';
import ChatGPTService from '../../services/ChatGPTService';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

const EmailView = observer(() => {
  const videoCreator = useVideoCreatorStore();
  const gptService = new ChatGPTService(videoCreator);
  const [isLoading, setIsLoading] = useState(false);
  const { story, replacementImages } = videoCreator;
  const artifacts = story?.storyArtifacts;
  const [openMedia, toggleMedia] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<
    ImageWithType[ImageKey] | null
  >(null);
  const [openDropdown, toggleDropdown] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const userInfo = useUser();

  const buttonRef = useRef<HTMLDivElement>(null);

  const {
    contentStudioEnableEmailSave,
    contentStudioEnableEmailShare,
    enableEmailRegeneration,
  } = useFlagsCombination(videoCreator.datoContext.currentRole);

  const contentRef = useRef<HTMLDivElement>(null);
  const savedContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    initializeContentStudioContent(videoCreator, story?.id, 'Email');
  }, [story?.id]);

  const parser = new DOMParser();

  useOutsideAlerter(buttonRef, () => {
    toggleDropdown(false);
  });
  const saved_emails = videoCreator.story?.savedEmail;
  let currSavedKey: number | undefined = undefined;
  let saved_email: string | undefined = undefined;

  if (saved_emails && !saved_email) {
    const timeArray = Object.keys(saved_emails) as unknown as number[];
    currSavedKey = Math.max(...timeArray);
    saved_email = saved_emails[currSavedKey].content;
  }

  const data = videoCreator.contentStudioGeneratedContent?.Email;

  let generatedContent = data?.content?.response as string;

  const hasBeenGenerated = data?.hasBeenGenerated;

  const ImageComponent = (src: string, alt: string) => (
    <ImageWrapper className="image-wrapper">
      <Kebab className="kabeb-action">
        <EllipsisIcon fillColor="#fff" />
        <Actions className="actions" isVisible={openDropdown}>
          <AddPhoto className="add-photo-action">
            <span>Add Photo</span>
            <div className="icon">
              <CameraIcon />
            </div>
          </AddPhoto>
          <DeleteButton className="delete-action">
            <span>Delete</span>
          </DeleteButton>
        </Actions>
      </Kebab>

      <Image
        style={{ maxHeight: 400 }}
        className="image"
        src={src!}
        alt={alt!}
      />
    </ImageWrapper>
  );

  const renderMainComponent = (content: string) => (
    <Main>
      <Content ref={buttonRef}>
        <TopContent>
          <Field>
            <span>From</span>
            <div className="content">
              <input type="text" value="newsletter@yourorganization.com" />
            </div>
          </Field>
          <Field hasBcc={true}>
            <span>To</span>
            <div className="content">
              <input type="text" value="Your Community" />
              <div className="cc">
                <span>Cc</span>
                <span>Bcc</span>
              </div>
            </div>
          </Field>
          <Field>
            <span>Subject</span>
            <div className="content">
              <input type="text" value="Email Story to Your Community" />
            </div>
          </Field>
        </TopContent>
        <EmailContent
          ref={contentRef}
          onClick={handleContentClick}
          dangerouslySetInnerHTML={{
            __html: marked.parse(content),
          }}
        />
      </Content>
    </Main>
  );

  useEffect(() => {
    if (!currSavedKey || !saved_email) return;
    const imgData =
      videoCreator.savedItemReplacementImages.emails[currSavedKey];

    if (imgData?.value?.url || imgData?.isRemoved) return;

    const doc = parser.parseFromString(saved_email, 'text/html');
    const imageWrapper = doc.querySelector('.image-wrapper');
    const imageSrc = imageWrapper?.querySelector('img');

    const src = imageSrc?.getAttribute('src');
    const alt = imageSrc?.getAttribute('alt');

    const dataToSave = {
      url: src!,
      alt: alt!,
      id: '',
      type: 'artifact',
    } as ImageWithType[ImageKey] | null;

    videoCreator.savedItemReplacementImages.emails = {
      ...videoCreator.savedItemReplacementImages.emails,
      [currSavedKey]: { value: dataToSave, isRemoved: false },
    };
  }, [currSavedKey, saved_email]);

  if (generatedContent) {
    if (artifacts?.length) {
      const artifact = artifacts[0];

      const regex = /^##[^\n]*\n/;
      const match = generatedContent?.match(regex);
      const src = replacementImages.email?.value?.url || artifact.url;
      const alt = replacementImages.email?.value?.alt || artifact.title;

      let replacement = ReactDOMServer.renderToString(
        ImageComponent(src!, alt!),
      );

      if (replacementImages.email?.isRemoved) {
        replacement = '';
      }

      if (match) {
        generatedContent = generatedContent?.replace(regex, (m: string) => {
          return m + replacement + '\n\n';
        });
      } else {
        generatedContent = replacement + `<br /><div>${generatedContent}</div>`;
      }
    }
  }

  if (saved_email && currSavedKey) {
    const doc = parser.parseFromString(saved_email, 'text/html');
    const imageWrapper = doc.querySelector('.image-wrapper');

    if (imageWrapper) {
      const imgData =
        videoCreator.savedItemReplacementImages.emails[currSavedKey];

      const imageSrc = imageWrapper?.querySelector('img');
      const isRemoved = imgData?.isRemoved === true;

      const src = imgData?.value?.url ?? imageSrc?.getAttribute('src');
      const alt = imgData?.value?.alt ?? imageSrc?.getAttribute('alt');

      const replacement = ReactDOMServer.renderToString(
        ImageComponent(src!, alt!),
      );

      imageWrapper.outerHTML = isRemoved ? '' : replacement;
      saved_email = doc.body.innerHTML;
    }
  }

  const handleContentClick = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
  ) => {
    const targetElement = e.target as Element;
    const kabebAction = targetElement.closest('.kabeb-action');
    if (kabebAction) {
      toggleDropdown(true);
    } else {
      toggleDropdown(false);
    }

    const addPhotoAction = targetElement.closest('.add-photo-action');
    if (addPhotoAction) {
      toggleMedia(true);
      toggleDropdown(false);
    }

    const deleteAction = targetElement.closest('.delete-action');
    if (deleteAction) {
      const updatedReplacementImages = { ...replacementImages };
      const dataToUpdate = { isRemoved: true, value: null };
      updatedReplacementImages.email = dataToUpdate;

      if (hasBeenGenerated) {
        videoCreator.replacementImages = updatedReplacementImages;
      } else if (currSavedKey) {
        videoCreator.savedItemReplacementImages.emails[currSavedKey] =
          dataToUpdate;
      }

      toggleDropdown(false);
    }
  };

  const handleReplaceAction = () => {
    if (!selectedImage) {
      toggleMedia(false);
      return;
    }
    const dataToUpdate = {
      value: selectedImage,
      isRemoved: false,
    };

    if (hasBeenGenerated) {
      videoCreator.replacementImages.email = dataToUpdate;
    } else {
      if (!currSavedKey) return;
      videoCreator.savedItemReplacementImages.emails[currSavedKey] =
        dataToUpdate;
    }

    toggleMedia(false);
  };

  const handleCopyRichText = () => {
    if ((!generatedContent || !hasBeenGenerated) && !saved_email) return;

    const contentElement = contentRef?.current;
    const clonedContentElement = contentElement?.cloneNode(true) as HTMLElement;

    const kebabActions =
      clonedContentElement?.querySelectorAll('.kabeb-action');

    kebabActions?.forEach((kebabAction) => {
      kebabAction.remove();
    });

    // Apply styles to specific elements
    const blockquotes = clonedContentElement?.querySelectorAll('blockquote');
    blockquotes?.forEach((blockquote) => {
      blockquote.style.fontStyle = 'italic';
    });

    const updatedContent = clonedContentElement?.innerHTML || '';

    handleCopyToClipboard(updatedContent, 'html');
  };

  const handleSave = async () => {
    if (
      !story?.id ||
      ((!generatedContent || !hasBeenGenerated) && !saved_email)
    ) {
      return;
    }

    try {
      setIsSaving(true);
      const contentElement = contentRef?.current;
      const clonedContentElement = contentElement?.cloneNode(
        true,
      ) as HTMLElement;

      const name = userInfo?.user?.fullName;
      const title =
        clonedContentElement.querySelector('h1')?.textContent ||
        clonedContentElement.querySelector('h2')?.textContent ||
        '';

      const updatedSavedEmails =
        await videoCreator.storyRepository?.saveBlogOrEmail(
          story,
          clonedContentElement.innerHTML,
          title,
          name!,
          'saved_email',
        );

      runInAction(() => (videoCreator.story!.savedBlog = updatedSavedEmails));
    } catch (error) {
      console.log('Error occurred: ', error);
    } finally {
      setIsSaving(false);
    }
  };

  const spinnerText = () => {
    if (isSaving) return 'Saving email data...';
    if (hasBeenGenerated) return 'Regenerating email...';
    return 'Generating email...';
  };

  return (
    <>
      {(isLoading || isSaving) && (
        <SpinningLoading
          customStyle={{
            top: 0,
            position: 'fixed',
            alignItems: 'center',
          }}
          text={spinnerText()}
        />
      )}

      {
        <Panel>
          <div className="share">
            {enableEmailRegeneration && (
              <RegenerateButton
                setIsLoading={setIsLoading}
                storyId={story?.id}
                transcriptionId={story?.transcription?.elementsJson?.id}
                promptTitle="Email"
                gptService={gptService}
              />
            )}
            {((generatedContent && hasBeenGenerated) || saved_email) && (
              <>
                {contentStudioEnableEmailShare && (
                  <ContentShare onCopy={handleCopyRichText} />
                )}
                {contentStudioEnableEmailSave && (
                  <Save onClick={handleSave}>Save</Save>
                )}
              </>
            )}
          </div>

          {openMedia && (
            <Modal
              isOpen={true}
              onClose={() => toggleMedia(false)}
              paddingHorizontal="0"
            >
              <PhotoModal
                TopComponent={
                  <PhotoModalTop
                    title="email"
                    isSelected={!!selectedImage}
                    replaceAction={handleReplaceAction}
                    selectedImage={selectedImage}
                  />
                }
                otherFields={['stock']}
                onCloseSelf={() => toggleMedia(false)}
                openPrevModal={() => {}}
                selectedImageUrl={selectedImage?.url || null}
                setSelectedImage={setSelectedImage}
                from="quotecard"
              />
            </Modal>
          )}

          {saved_email && !hasBeenGenerated && renderMainComponent(saved_email)}

          {generatedContent &&
            hasBeenGenerated &&
            renderMainComponent(generatedContent)}
        </Panel>
      }
    </>
  );
});

export default EmailView;
const Panel = styled.div`
  .share {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
`;
const Main = styled.div`
  margin: 20px auto;
  border-radius: 8px;
  border: 1px solid #484848;
  max-height: 700px;
  overflow: scroll;
`;
const Content = styled.div`
  max-width: 500px;
  margin: 30px auto;
  background-color: #fff;
  color: #030419;
`;
const TopContent = styled.div``;
const Field = styled.div<{ hasBcc?: boolean }>`
  display: flex;
  border-bottom: 1px solid #48484844;
  align-items: center;
  padding: 0 20px;
  gap: 10px;
  span {
    font-weight: 500;
    color: #333333c7;
  }
  .content {
    input[type='text'] {
      border: 0;
      outline: 0;
      width: 100%;
      font-size: 14px;
      color: #030419;
    }
    margin: 12px 0;
    margin-left: ${(props) => (props.hasBcc ? '20px' : 0)};
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .cc {
      display: flex;
      gap: 5px;
    }
  }
`;
const EmailContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  * {
    padding-left: 40px;
    padding-right: 40px;
  }

  h1 {
    text-align: center;
  }
  p strong {
    font-weight: 800;
  }
  & > p:has(img) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  blockquote {
    font-style: italic;
  }

  .image-wrapper {
    position: relative;
    max-height: 400px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    * {
      padding-left: 0;
      padding-right: 0;
    }
  }

  img {
    margin: 0 auto;
    max-height: 400px;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }

  .kebab-action {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  max-height: 400px;
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
  * {
    padding-left: 0;
    padding-right: 0;
  }
`;

const Image = styled.img`
  margin: 0 auto;
  max-height: 400px;
  width: 100%;
  object-fit: cover;
  object-position: top;
`;

const Kebab = styled.div`
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
`;

const Actions = styled(ActionsWrapper)``;

const AddPhoto = styled(ActionButton)`
  padding-left: 10px !important;
  padding-right: 10px !important;
`;

const DeleteButton = styled(ActionButton)`
  padding-left: 10px !important;
  padding-right: 10px !important;
  &:hover {
    background-color: #ef5d6f;
  }
`;

const CopyButton = styled.div`
  position: absolute;
  right: 0;
  top: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: #f2d093;
`;

const Save = styled.button`
  border: 0;
  outline: 0;
  background-color: #17c964;
  gap: 8px;
  width: 148px;
  height: 40px;
  border-radius: 10px;
  padding: 10px 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #03041a;
  cursor: pointer;
`;
