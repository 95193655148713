import { RequestDocument } from 'graphql-request';

export const AI_FLOW_BY_TITLE_AND_CATEGORY_ACTIVE_QUERY: RequestDocument = `query getAiFlow($title: String!, $category: String!) {
  aiFlow(filter: {title: {eq: $title}, category: {eq: $category}, active: {eq: true}}) {
    id
    title
    steps {
      label
      systemMessageTemplate
      userPromptTemplate
      overrideMessagesFromStep
      maxAttempts
      iterateOver
      modelName
      temperature
      temperatureFunction
      retryConditionFunction
      filterFunction
      reducerFunction
    }
    resultMapperFunction
    logEnabled
  }
}`;

export const AI_FLOWS_BY_CATEGORY_AND_ACTIVE_QUERY: RequestDocument = `query getAiFlows($categories: [String]) {
  allAiFlows(filter: {category: {in: $categories}, active: {eq: true}}) {
    id
    title
    category
  }
}`;

export const AIPROMPT_QUERY: RequestDocument = `query {
  allAiPrompts{
    id
    promptFields{
      id
      name
      description
    }
    functionDescription,
    description
    title
    followUp
  }
}`;

export const STORIES_ARTIFACT_AI_TITLE_QUERY: RequestDocument = `query getStories($ids: [ItemId]) {
    allStories(filter: {id: {in: $ids}}) {
      id
      storyArtifacts {
        id
        title
      }
    }
}`;

export const SHOWCASES_ARTIFACT_AI_TITLE_QUERY: RequestDocument = `query getShowcases($ids: [ItemId]) {
    allShowcases(filter: {id: {in: $ids}}) {
      id
      organizationArtifacts {
        id
        title
      }
    }
}`;

export const AIPROMPT_SINGLE_BY_TITLE_QUERY: RequestDocument = `query getAipromptSingle($title: String!){
  aiPromptSingle(filter: {title: {matches: {pattern: $title, caseSensitive:false} }}){
    id
    promptFields{
      id
      name
      description
    }
    description
    title
    followUp
  }
} `;
