import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { withClerkProvider } from './withClerkProvider';
import { useAuth, useClerk, useSession, useUser } from '@clerk/clerk-react';

// Stores and Hooks
import { useStore } from '@src/stores-v2/StoreContext';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { useStoreInitialization } from '@src/hooks/useStoreInitialization';
import { useLaunchDarklyInit } from '@src/hooks/useLaunchDarklyInit';

// Components
import { VideoCreator } from '../components/VideoCreator';
import ContentStudio from '../components/ContentStudio';
import BrandKit from '../components/BrandKit';
import RootLayout from './RootLayout';
import Dashboard from '../components/Dashboard/';
import BrollPage from '../components/BrollPage';

// Services
import { DatoContextService } from '@src/services/DatoContextService';
import { RenderModalCtx } from 'datocms-plugin-sdk';

const EditorEntry = observer(() => {
  const rootStore = useStore();
  const videoCreator = useVideoCreatorStore();
  const { analyticsStore } = rootStore;

  // Clerk hooks
  const { session, isLoaded: isSessionLoaded } = useSession();
  const { user, isLoaded: isUserLoaded } = useUser();
  const { orgRole, orgSlug } = useAuth();

  // External services
  const ldClient = useLDClient();

  // URL Parameters
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const { storyId, videoId, env } = params;

  // Load Dato context
  const datoCtx = React.useMemo(() => DatoContextService.loadDatoContext(), []);

  // Initialize stores
  const { isReady } = useStoreInitialization({
    storyId,
    env,
    datoCtx,
    clerkUser: user,
    clerkSession: session,
    isClerkLoaded: isSessionLoaded && isUserLoaded,
  });

  // Initialize LaunchDarkly
  useLaunchDarklyInit({
    ldClient,
    storyId,
    clerkUser: user,
    isClerkLoaded: isSessionLoaded,
    orgRole,
    orgSlug,
  });

  // Set analytics context
  useEffect(() => {
    if (isReady && storyId) {
      const org = orgSlug || 'unknown';
      analyticsStore.setContext(storyId, org);
    }
  }, [isReady, storyId, orgSlug]);

  // Link videoCreatorStore
  // TODO: Refactor when we remove linkFunctions from videoCreatorStore
  useEffect(() => {
    if (datoCtx) {
      // Internal user flow (Dato)
      videoCreator.linkFunctions({
        environment: env,
        currentUserAccessToken: datoCtx.currentUserAccessToken,
        currentUser: datoCtx.currentUser,
        currentRole: datoCtx.currentRole,
      } as RenderModalCtx);
    } else if (isSessionLoaded && session?.status === 'active') {
      // External user flow (Clerk)
      session.getToken().then((token) => {
        if (token) {
          videoCreator.linkFunctions({
            environment: env,
            currentUserAccessToken: undefined,
            clerkUserSessionToken: token,
            currentUser: session as any,
            currentRole: null as any,
          } as any as RenderModalCtx);
        }
      });
    }
  }, [datoCtx, isSessionLoaded, session, env]);

  // Router configuration
  const videoCreatorElement = (
    <RootLayout>
      <VideoCreator />
    </RootLayout>
  );

  const router = createBrowserRouter([
    {
      path: '/',
      element: videoCreatorElement,
    },
    {
      path: '/creator',
      element: videoCreatorElement,
    },
    {
      path: '/content',
      element: (
        <RootLayout>
          <ContentStudio params={params} />
        </RootLayout>
      ),
    },
    {
      path: '/story-manager',
      element: (
        <RootLayout>
          <Dashboard params={params} />
        </RootLayout>
      ),
    },
    {
      path: '/broll',
      element: <BrollPage />,
    },
    {
      path: '/brand-kit',
      element: (
        <RootLayout>
          <BrandKit params={params} />
        </RootLayout>
      ),
    },
  ]);

  if (!isReady) {
    return <div>Loading...</div>;
  }

  return <RouterProvider router={router} />;
});

// Provider composition
export default withClerkProvider(
  withLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID as string,
    options: {
      useReport: true,
      streaming: false,
    },
  })(EditorEntry),
);
