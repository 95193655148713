import { makeAutoObservable } from 'mobx';
import VideoCreatorStore, { VIDEO_DEFAULT_WIDTH } from '../VideoCreatorStore';
import { ElementState } from '../../renderer/ElementState';
import { AspectRatio } from '../../types.ts/video';
import { deepClone } from '../../utility/deepClone';

class ReframingModeManager {
  store: VideoCreatorStore;

  actualVideoWidth: number | null = null;
  rectangleTrack: number | null = null;
  relevantElementSources: ElementState['source'][] = [];

  constructor(store: VideoCreatorStore) {
    makeAutoObservable(this, {
      store: false,
    });
    this.store = store;
  }

  public async toggleReframingMode() {
    const activeElement = this.findActiveRelevantElement();
    if (activeElement) {
      await this.enterReframingMode();
    } else {
      await this.exitReframingMode();
    }
  }

  private findActiveRelevantElement(): ElementState | undefined {
    if (this.store.currentVideo!.aspectRatio === AspectRatio.AR_16_9) {
      return undefined;
    }
    const activeElement = this.store.getActiveElement();
    return activeElement && this.isRelevantElement(activeElement)
      ? activeElement
      : undefined;
  }

  private isRelevantElement(element: ElementState): boolean {
    return (
      this.store.isRegularImageElement(element) ||
      this.store.isVideoElement(element)
    );
  }

  private getRelevantElementSources(): ElementState['source'][] {
    if (this.store.currentVideo!.aspectRatio === AspectRatio.AR_16_9) {
      return [];
    }
    return (this.store.renderer?.state?.elements || [])
      .filter((element) => this.isRelevantElement(element))
      .map((element) => this.store.renderer!.getSource(element));
  }

  private getVideoSource(): Record<string, any> {
    return this.store.renderer!.getSource();
  }

  private async setVideoSource(videoSource: Record<string, any>) {
    this.store.currentVideo!.videoSource = videoSource;
    await this.store.renderer?.setSource(videoSource);
  }

  private async enterReframingMode() {
    if (this.isModeActive()) {
      return;
    }

    const videoSource = this.getVideoSource();

    this.actualVideoWidth = videoSource.width;
    this.rectangleTrack = this.store.getMaxTrack() + 1;
    this.relevantElementSources = this.getRelevantElementSources();

    await this.setVideoSource(this.modifyVideoSourceForEnter(videoSource));
  }

  private modifyVideoSourceForEnter(
    videoSourceInput: Record<string, any>,
  ): Record<string, any> {
    const videoSource = deepClone(videoSourceInput);
    videoSource.width = VIDEO_DEFAULT_WIDTH;

    const elements = videoSource.elements;
    for (let i = 0; i < elements.length; i++) {
      const relevantIndex = this.getRelevantIndexOf(elements[i]);
      if (relevantIndex !== -1) {
        this.scaleElementToReframingMode(elements[i], relevantIndex);
      }
    }
    this.insertPreviewRectangles(videoSource, {
      time: 0,
      duration: this.store.duration,
    });

    return videoSource;
  }

  public restoreVideoSourceForExit(
    videoSourceInput: Record<string, any>,
  ): Record<string, any> {
    if (!this.isModeActive()) {
      return videoSourceInput;
    }

    const videoSource = deepClone(videoSourceInput);
    videoSource.width = this.actualVideoWidth;

    const elements = videoSource.elements;
    for (let i = 0; i < elements.length; i++) {
      const relevantIndex = this.getRelevantIndexOf(elements[i]);
      if (relevantIndex !== -1) {
        this.scaleElementFromReframingMode(elements[i], relevantIndex);
      }
      if (this.isPreviewRectanglesElement(elements[i])) {
        this.removePreviewRectanglesAt(i, videoSource);
        i--;
      }
    }

    return videoSource;
  }

  public async exitReframingMode() {
    if (!this.isModeActive()) {
      return;
    }

    const videoSource = this.restoreVideoSourceForExit(this.getVideoSource());

    this.actualVideoWidth = null;
    this.rectangleTrack = null;
    this.relevantElementSources = [];

    await this.setVideoSource(videoSource);
    await this.unselectActiveElement();
  }

  private getRelevantIndexOf(elementSource: ElementState['source']): number {
    return this.relevantElementSources.findIndex(
      (el) => el.id === elementSource.id,
    );
  }

  private async unselectActiveElement() {
    await this.store.setActiveElements();
  }

  private scaleElementToReframingMode(
    elementSource: ElementState['source'],
    relevantIndex: number,
  ) {
    const relevantElementSource = this.relevantElementSources[relevantIndex];
    if (!relevantElementSource) {
      return;
    }

    elementSource.width = '100%';

    const currWidth = parseFloat(elementSource.width || '100');
    const prevWidth = parseFloat(relevantElementSource.width || '100');
    const prevX = parseFloat(relevantElementSource.x || '50');

    if (prevX !== 50) {
      const scaledX =
        ((prevX - 50 + (prevWidth / currWidth) * 50) * currWidth) / prevWidth;
      elementSource.x = `${scaledX}%`;
    }
  }

  private scaleElementFromReframingMode(
    elementSource: ElementState['source'],
    relevantIndex: number,
  ) {
    const relevantElementSource = this.relevantElementSources[relevantIndex];
    if (!relevantElementSource) {
      return;
    }

    const currWidth = parseFloat(elementSource.width || '100');
    const prevWidth = parseFloat(relevantElementSource.width || '100');
    const currX = parseFloat(elementSource.x || '50');

    const xDiff = (currX - 50) * (prevWidth / currWidth);
    elementSource.x = `${50 + xDiff}%`;

    elementSource.width = relevantElementSource.width;
  }

  private insertPreviewRectangles(
    videoSource: Record<string, any>,
    elementSource: ElementState['source'],
  ) {
    const rectangleWidth = Number(
      (VIDEO_DEFAULT_WIDTH - this.actualVideoWidth!) / 2,
    ).toFixed(4);
    videoSource.elements.push({
      ...elementSource,
      type: 'composition',
      track: this.rectangleTrack,
      locked: true,
      elements: [
        this.makeReframingRectangleElement({
          x: rectangleWidth,
          width: rectangleWidth,
        }),
        this.makeReframingRectangleElement({
          x: '100%',
          width: rectangleWidth,
        }),
        ...this.makeReframingDashedLineElements({
          x: rectangleWidth,
          width: 3,
        }),
        ...this.makeReframingDashedLineElements({
          x: Number(
            Number(rectangleWidth) + this.actualVideoWidth! + 3,
          ).toFixed(4),
          width: 3,
        }),
      ].map((el, index) => ({ ...el, track: index + 1 })),
    });
  }

  private removePreviewRectanglesAt(
    index: number,
    videoSource: Record<string, any>,
  ) {
    videoSource.elements.splice(index, 1);
  }

  public isPreviewRectanglesElement(
    elementSource: ElementState['source'],
  ): boolean {
    return (
      elementSource.type === 'composition' &&
      elementSource.elements?.length >= 4 &&
      elementSource.elements.every(
        (el: Record<string, any>) => el.type === 'shape',
      )
    );
  }

  private makeReframingRectangleElement(
    variableProperties: Record<'x' | 'width', string | number>,
  ): Record<string, string | number | boolean | null | undefined> {
    return {
      type: 'shape',
      time: 0,
      height: '100%',
      x_anchor: '100%',
      locked: true,
      fill_color: 'rgb(3, 4, 26, 0.7)',
      path: 'M 0 0 L 100 0 L 100 100 L 0 100 L 0 0 Z',
      ...variableProperties,
    };
  }

  private makeReframingDashedLineElements(
    variableProperties: Record<'x' | 'width', string | number>,
  ): Record<string, string | number | boolean | null | undefined>[] {
    const totalDashes = 40;
    return Array(totalDashes)
      .fill('')
      .map((_, i) => {
        const dashHeight = 100 / (totalDashes * 2); // Height of each dash
        return {
          ...this.makeReframingRectangleElement(variableProperties),
          fill_color: '#006FEE',
          height: `${dashHeight}%`,
          y: `${i * dashHeight * 2}%`, // Move each dash down by its height + a gap
          y_anchor: '0%',
        };
      });
  }

  public isModeActive(): boolean {
    return this.rectangleTrack != null;
  }
}

export default ReframingModeManager;
