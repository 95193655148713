import styled, { css } from 'styled-components';
import { ProduceIcon } from '../../common/icons';
import SpinningLoading from '../../SpinningLoading';
import Modal from '../../common/Modal';
import AIClipProducerResults from '../../ClipProducer/AIClipProducerResults';
import SocialClipButtonList from '../../common/SocialClipButtonList';
import ClipDurationButtonList from '../../ClipProducer/ClipDurationButtonList';
import { ClipProducerUIProps } from '../../ClipProducer/AIClipProducer';

const ClipProducerModalUI = (
  props: ClipProducerUIProps & { closeModal: () => void },
) => {
  const {
    supportedPresets,
    isProducing,
    retryCount,
    selectedDuration,
    setSelectedDuration,
    selectedPresets,
    setSelectedPresets,
    onPresetClick,
    customPresetText,
    setCustomPresetText,
    selectedSocials,
    setSelectedSocials,
    producedClips,
    showModal,
    isProducingDisable,
    onProduceButtonClick,
    closeProducingModal,
    closeModal,
  } = props;

  const onSocialSelect = (social: string) => {
    if (selectedSocials.includes(social)) {
      setSelectedSocials(selectedSocials.filter((s) => s !== social));
    } else {
      setSelectedSocials([social]);
    }
  };

  const stopProducingAndClose = () => {
    closeProducingModal();
    props.closeModal();
  };

  return (
    <>
      <Modal isOpen={true} closeModal={stopProducingAndClose}>
        <Wrapper>
          <Content>
            {isProducing && (
              <div style={{ color: '#17c964' }}>
                <SpinningLoading
                  Ico={<ProduceIcon width={'24'} height={'24'} />}
                  text={`Generating clips${
                    retryCount > 0 ? `. Retrying (${retryCount})` : ''
                  }`}
                />
              </div>
            )}
            <TopHeading>Let's produce some clips</TopHeading>

            <SocialMediaList>
              <Leading> Select social platform to optimize clips</Leading>
              <SocialClipButtonList
                onSocialSelect={onSocialSelect}
                selectedSocials={selectedSocials}
              />
            </SocialMediaList>

            <PresetsList>
              <Leading>Find social clips based on theme</Leading>
              <div className="options-list">
                {supportedPresets
                  .filter((preset) => !preset.isCustom)
                  .map((preset) => (
                    <PresetOption
                      key={preset.title}
                      active={selectedPresets.includes(preset.title)}
                      onClick={() => onPresetClick(preset.title)}
                    >
                      {preset.title}
                    </PresetOption>
                  ))}
              </div>
              <div className="input">
                <label htmlFor="customPresetInput">Customize</label>
                <PresentTextArea
                  id="customPresetInput"
                  placeholder="Type in what you'd like to see"
                  value={customPresetText}
                  onChange={(e) => {
                    setCustomPresetText(e.target.value);
                    setSelectedPresets([]);
                  }}
                />
              </div>
            </PresetsList>

            <DurationFilter>
              <Leading>
                Choose your desired approximate length in seconds
              </Leading>
              <ClipDurationButtonList
                setSelectedDuration={setSelectedDuration}
                selectedDuration={selectedDuration}
              />
            </DurationFilter>

            <ProduceButton
              onClick={onProduceButtonClick}
              disabled={isProducingDisable}
            >
              <ProduceIcon /> Produce
            </ProduceButton>
          </Content>
        </Wrapper>
      </Modal>
      {showModal && Boolean(producedClips?.length) && (
        <Modal isOpen={true} closeModal={stopProducingAndClose}>
          <AIClipProducerResults
            hidePlatformName={true}
            sourcePlatform="content-studio"
            onClose={stopProducingAndClose}
            fragments={producedClips!}
            isProducing={isProducing}
          />
        </Modal>
      )}
    </>
  );
};

export default ClipProducerModalUI;

const Wrapper = styled.div`
  border-radius: 10px;
  background-color: #03041a;
  width: 942px;
  border: 1px solid #484848;
`;

const Content = styled.div`
  max-width: 480px;
  margin: 0 auto;
  display: grid;
  place-items: center;
  background-color: #03041a;
  gap: 10px;
  padding: 50px 0;
`;

const TopHeading = styled.h2`
  font-weight: 700;
  font-size: 32px;
  line-height: 38.73px;
  text-align: center;
  color: #f3e9d7;
  margin: 0;
`;

const Leading = styled.div<{ color?: string }>`
  width: 100%;
  color: ${(props) => props.color ?? '#f3e9d7'};
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;
  text-align: center;
`;

const PresentTextArea = styled.textarea`
  box-sizing: border-box;
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid #484848;
  text-align: left;
  font-size: 10px;
  line-height: 150%;
  background-color: transparent;
  color: #f3e9d7;
  width: 100%;
  resize: none;
  height: 70px;
  outline: none;
  &::placeholder {
    color: #484848;
  }
`;

const SocialMediaList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const DurationFilter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
`;

const ProduceButton = styled.div.attrs((props: { disabled: boolean }) => props)`
  width: 240px;
  display: flex;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: #17c964;
  box-sizing: border-box;

  color: #03041a;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    `
    cursor: not-allowed;
    color: #030419;
    opacity: 0.6;
  `}
`;

const PresetsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;

  .options-list {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex: 1;
    gap: 8px;
  }

  .input {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    label {
      font-size: 10px;
      color: #f3e9d7;
    }
  }
  gap: 16px;
`;

const PresetOption = styled.div.attrs((props: { active: boolean }) => props)`
  padding: 8px;
  position: relative;
  border-radius: 8px;
  width: calc(50% - 4px);
  box-sizing: border-box;
  border: 1px solid #484848;
  cursor: pointer;
  color: #484848;
  font-size: 10px;
  font-weight: 500;
  line-height: 150%;

  ${(props) =>
    props.active &&
    css`
      color: #f2d093;
      border: 1px solid #f2d093;
    `}

  &:hover {
    color: #f2d093;
  }

  &::after {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 14px;
    border: 1px solid #848484;
    right: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    ${(props) =>
      props.active &&
      `
      font-size: 9px;
      font-weight: 600;
      text-align: center;
      content: '✓';
      background: #4ad067;
    `}
  }
`;
