import { makeAutoObservable, runInAction } from 'mobx';

import VideoCreatorStore from '../VideoCreatorStore'; // Adjust import path as needed
import { getAllAiFlowsByCategory } from '../../aiFlow/AIFlowLoader';

class ProducedContentManager {
  store: VideoCreatorStore;

  clipProducerPresets:
    | { title: string; category: string; isCustom: boolean }[]
    | null = null;

  constructor(store: VideoCreatorStore) {
    makeAutoObservable(this, {
      store: false,
    });
    this.store = store;
  }

  async init() {
    await this.loadClipProducerPresets();
  }

  loadClipProducerPresets = async () => {
    try {
      const presets = (
        await getAllAiFlowsByCategory(this.store, [
          'clip_producer',
          'clip_producer_custom',
        ])
      ).map((flow) => ({
        title: flow.title,
        category: flow.category,
        isCustom: flow.category === 'clip_producer_custom',
      }));
      console.log('Loaded clip producer presets', presets);
      this.clipProducerPresets = presets;
    } catch (err) {
      console.error('Error loading clip producer presets', err);
    }
  };
}

export default ProducedContentManager;
