import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import styled from 'styled-components';

import CheckIcon from '../../svgs/CheckIcon';
import { SaveOrDiscardModal } from './SaveOrDiscardModal';
import { AspectRatio } from '../../types.ts/video';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

const AspectRatiosDropdown = observer(() => {
  const videoCreator = useVideoCreatorStore();
  const selectedAspectRatio =
    videoCreator.currentVideo?.aspectRatio || AspectRatio.AR_16_9;
  const [dropdownOpen, toggleDropdown] = useState<boolean>(false);
  const [aspectRatioToProceed, setAspectRatioToProceed] =
    useState<AspectRatio | null>(null);

  const aspectRatioNames = {
    [AspectRatio.AR_16_9]: 'Landscape',
    [AspectRatio.AR_1_1]: 'Square',
    [AspectRatio.AR_9_16]: 'Portrait',
  };

  const renderAspectSquare = () => {
    switch (selectedAspectRatio) {
      case AspectRatio.AR_16_9:
        return <SixteenByNine />;
      case AspectRatio.AR_1_1:
        return <OneByOne />;
      case AspectRatio.AR_9_16:
        return <NineBySixteen />;
    }
  };

  const renderAspectRatioSelect = () => {
    return (
      <AspectItem
        onClick={() =>
          videoCreator.isVideoCreatorReady && toggleDropdown(!dropdownOpen)
        }
      >
        {renderAspectSquare()}
        <DropdownIcon isOpen={dropdownOpen}>
          <ArrowDownIcon />
        </DropdownIcon>
      </AspectItem>
    );
  };

  const handleChange = async (aspectRatio: AspectRatio) => {
    setAspectRatioToProceed(null);
    await videoCreator.loadVideoWithAspectRatio(aspectRatio);
    if (!videoCreator.currentVideo?.id) {
      await videoCreator.adjustTextDimensions(aspectRatio);
    }

    if (aspectRatio === AspectRatio.AR_1_1) {
      await videoCreator.removeBlackFrames();
    }
  };

  return (
    <Main disabled={!videoCreator.isVideoCreatorReady}>
      {renderAspectRatioSelect()}
      {dropdownOpen && (
        <>
          <DropdownBackground
            onClick={() => toggleDropdown(false)}
          ></DropdownBackground>
          <AspectDropDown>
            {Object.entries(aspectRatioNames).map(
              ([aspectRatio, aspectText], index) => {
                const isSelected = selectedAspectRatio === aspectRatio;
                const color = isSelected ? '#17C964' : '#03041A';
                const borderColor = isSelected ? '#17C964' : '#484848';

                return (
                  <AspectColumn>
                    <AspectRow
                      onClick={async () => {
                        toggleDropdown(false);
                        if (
                          !videoCreator.currentVideo?.id &&
                          videoCreator.currentVideo?.aspectRatio !==
                            AspectRatio.AR_16_9
                        ) {
                          setAspectRatioToProceed(aspectRatio as AspectRatio);
                          return;
                        } else {
                          await videoCreator.saveStoryAndVideo();
                        }
                        handleChange(aspectRatio as AspectRatio);
                      }}
                    >
                      <AspectText>
                        <Check color={color} borderColor={borderColor}>
                          {isSelected && <CheckIcon fillColor="#03041A" />}
                        </Check>

                        {aspectText}
                      </AspectText>
                      {aspectRatio}
                    </AspectRow>
                    {index !== 2 && (
                      <HorizontalLine
                        height="2"
                        viewBox="0 0 300 2"
                        fill="none"
                      >
                        <path d="M0 1L300 1.00002" stroke="#484848" />
                      </HorizontalLine>
                    )}
                  </AspectColumn>
                );
              },
            )}
          </AspectDropDown>
        </>
      )}
      {aspectRatioToProceed && (
        <SaveOrDiscardModal
          closeModal={() => setAspectRatioToProceed(null)}
          action={() => handleChange(aspectRatioToProceed)}
        />
      )}
    </Main>
  );
});

export default AspectRatiosDropdown;

const Main = styled.div<{ disabled: boolean }>`
  position: relative;
  z-index: 1;
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? 'wait' : 'pointer')};
`;

const DropdownBackground = styled.div`
  position: fixed;
  z-index: 999;
  inset: 0;
  box-sizing: border-box;
`;

const ArrowDownIcon = () => (
  <svg width="10" height="6" viewBox="0 0 10 6" fill="none">
    <path d="M9 5.5L5 1.5L1 5.5" stroke="currentColor" strokeWidth="2" />
  </svg>
);

const OneByOne = styled.div`
  border: 1px solid #f2d093;
  width: 16px;
  height: 16px;
`;

const NineBySixteen = styled.div`
  border: 1px solid #f2d093;
  width: 8px;
  height: 16px;
`;

const SixteenByNine = styled(NineBySixteen)`
  transform: rotate(90deg);
`;

const DropdownIcon = styled.div<{ isOpen: boolean }>`
  width: 10px;
  height: 6px;
  margin-left: auto;
  display: flex;
  color: #f2d093;

  ${(props) => !props.isOpen && `rotate: 180deg;`}
`;

const AspectText = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: #f3e9d7;
`;
const AspectColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const AspectRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-itmes: center;
  font-size: 12px;
  color: #f3e9d7;
`;

const AspectDropDown = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1000;
  top: 25px;
  right: 0;
  width: 177px;
  gap: 10px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #484848;
  background: #03041a;
  box-shadow: 8px 16px 8px 0px rgba(0, 0, 0, 0.4);
`;

const Check = styled.div<{ color: string; borderColor: string }>`
  display: flex;
  width: 10px;
  height: 10px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  border-radius: 80px;
  background: ${(props) => props.color};
  border: 1px solid ${(props) => props.borderColor};
`;

const AspectItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
`;

const HorizontalLine = styled.svg`
  width: 100%;
`;
